<template>
  <div>
    {{ t('shop.productSubscription.bookedPrice') }}
    <span class="font-bold">{{ n(bookedOrderPrice, 'currency') }}</span>

    <DynamicPopup wrapper-classes="!inline">
      <template #trigger>
        <FaIcon
          class="ml-sm"
          icon-class="fad fa-circle-info"
          style="
            --fa-primary-color: var(--thm-white-base);
            --fa-secondary-color: var(--thm-status-warn-base);
            --fa-secondary-opacity: 1;
          "
        />
      </template>
      <template #content>
        <InfoFrame
          class="min-w-[280px] sm:min-w-[315px] max-w-[350px] sm:max-w-[450px]"
        >
          <p
            class="text-sm font-deco-alt leading-xs text-[var(--thm-info-popup-text)]"
          >
            {{ t('shop.productSubscription.bookedPrice.info') }}
          </p>
        </InfoFrame>
      </template>
    </DynamicPopup>
  </div>
</template>

<script setup lang="ts">
import { DynamicPopup } from '@/complib';
import FaIcon from '@/components/fa-icon.vue';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';

const { t, n } = useTrans();
defineProps({
  bookedOrderPrice: {
    type: Number,
    required: true,
  },
  quantity: {
    type: Number,
    required: false,
    default: 1,
  },
});
</script>
